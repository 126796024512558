import * as React from 'react'
import { graphql } from 'gatsby'
import Page from '../../components/Common/Page'
import IndexLayout from '../../layouts'
import OurWorkDetailSection from '../../components/pages/WorkPage/OurWorkDetailSection'
import OurWorkGallerySection from '../../components/pages/WorkPage/OurWorkGallerySection'
import OtherProjectsSection from '../../components/pages/WorkPage/OtherProjectsSection'

const WorkTemplate: React.FC<any> = ({ data }) => (
  <IndexLayout transparent>
    <Page>
      <OurWorkDetailSection data={data} />
      <OurWorkGallerySection data={data} />
      <OtherProjectsSection data={data} />
    </Page>
  </IndexLayout>
)

export default WorkTemplate

export const query = graphql`
  query WorkTemplateQuery($id: String!) {
    strapiOurWorks(id: { eq: $id }) {
      CardImage {
        localFile {
          publicURL
        }
      }
      Category
      Client
      Slug
      HeroImage {
        localFile {
          publicURL
        }
      }
      Images {
        localFile {
          publicURL
        }
      }
      LongDesc
      ShortDesc
      Title
      TypeOfWork
      Website
      Year
      id
      strapiId
    }
    allStrapiOurWorks {
      totalCount
      edges {
        node {
          CardImage {
            localFile {
              publicURL
            }
          }
          Client
          Slug
          Category
          Title
          id
          strapiId
        }
      }
    }
  }
`
