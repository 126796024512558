import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { navigate } from 'gatsby'

const WorkBox = styled.div(() => [tw`col-span-1 flex flex-col justify-start items-start sm:justify-start`])

const ImageContainer = styled.img(() => [
  tw`flex justify-center items-center self-start w-full h-[160px] md:h-[184px] rounded-[10px] mb-6 cursor-pointer object-cover overflow-hidden hover:opacity-80`
])

const ValueTitle = styled.h4(() => [tw`font-extrabold text-xl text-spaceGray text-left mt-0 cursor-pointer`])

const LowerValue = styled.span(() => [tw`font-medium text-spaceGray cursor-pointer`])

interface Props {
  className?: string
  data: WorkCardData
}
export interface WorkCardData {
  id: string
  Category: string
  Title: string
  Client: string
  Slug: string
  CardImage: {
    localFile: {
      publicURL: string
    }
  }
}

const OtherWorkCard: React.FC<Props> = ({ className, data }) => (
  <WorkBox className={className} onClick={() => navigate(`/work/${data.Slug}`)}>
    <ImageContainer src={data.CardImage.localFile.publicURL} />
    <ValueTitle>
      {data.Title}
      <br />
    </ValueTitle>
    {!!data.Category && <LowerValue>{data.Category}</LowerValue>}
  </WorkBox>
)

export default OtherWorkCard
