import * as React from 'react'
import tw, { styled } from 'twin.macro'
import OtherWorkCard, { WorkCardData } from './OtherWorkCard'

const StyledContainer = styled.div`
  ${tw`bg-[#F3F3F4]`}
`

const InnerContainer = styled.section(() => [
  tw`relative md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto`
])

const CardListContainer = styled.div(() => [
  tw`pb-18 flex justify-center overflow-x-auto gap-x-12 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 justify-center flex-col md:flex-row`
])

const Title = styled.p(() => [
  tw`flex w-full text-2xl md:text-[32px] justify-center font-bold text-spaceGray pt-12 mb-16`
])

interface OtherProjectsSectionProps {
  className?: string
  data: any
}

const OtherProjectsSection: React.FC<OtherProjectsSectionProps> = ({ className, data }) => (
  <StyledContainer className={className}>
    <InnerContainer>
      <Title>Other projects</Title>
      <CardListContainer>
        {data.allStrapiOurWorks.edges &&
          data.allStrapiOurWorks.edges
            .slice(0, 4)
            .map((list: { node: WorkCardData }) => <OtherWorkCard data={list.node} />)}
      </CardListContainer>
    </InnerContainer>
  </StyledContainer>
)

export default OtherProjectsSection
