import { navigate } from 'gatsby';
import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { WorkTemplateProps } from '../../../templates/work/types';
import { Button, ButtonType } from '../../Common/Button';

const StyledContainer = styled.section(() => [
  tw`flex flex-col items-center relative h-auto mx-auto px-0 md:px-8 lg:max-w-screen-lg xl:max-w-screen-xl mb-12 md:mb-20`
]);

const ImageContainer = styled.div(() => [tw`grid grid-cols-2 max-w-full gap-10 bg-transparent mb-8 md:mb-20`]);

const Pic = styled.img(() => [tw`col-span-2 h-[238px] md:h-[356px] md:col-span-1 w-[561px] m-auto object-cover object-contain`]);

interface OurWorkGallerySectionProps {
  className?: string;
  data: WorkTemplateProps;
}

const OurWorkGallerySection: React.FC<OurWorkGallerySectionProps> = ({ className, data }) => {
  const filterCurrentPath = (allPaths: WorkTemplateProps) => {
    const currentClient = allPaths.strapiOurWorks.Client;
    const { length } = allPaths.allStrapiOurWorks.edges;
    const currentIndex = allPaths.allStrapiOurWorks.edges.findIndex(elem => elem.node.Client === currentClient);
    if (currentIndex + 1 >= length) {
      return allPaths.allStrapiOurWorks.edges[0].node.Slug;
    }
    return allPaths.allStrapiOurWorks.edges[currentIndex + 1].node.Slug;
  };

  return (
    <StyledContainer className={className}>
      <ImageContainer>
        {data.strapiOurWorks.Images.map((Image: any) => (
          <Pic src={Image.localFile.publicURL} />
        ))}
      </ImageContainer>
      <Button
        type={ButtonType.SECONDARY}
        onClick={() => navigate(`/work/${filterCurrentPath(data)}`)}
      >
        Next project
      </Button>
    </StyledContainer>
  );
};

export default OurWorkGallerySection;
