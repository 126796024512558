import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { WorkTemplateProps } from '../../../templates/work/types'

const StyledContainer = styled.section(() => [tw`relative h-auto ml-auto mr-auto mx-auto`])

const Container = styled.section(() => [
  tw`relative mb-6 md:mb-0 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto`
])

const HeadingContainer = styled.div(() => [
  tw`grid grid-cols-2 max-w-full gap-10 gap-y-6 md:gap-y-10 md:gap-20 bg-transparent mb-0 md:mb-20`
])

const ValueBox = styled.div(() => [tw`col-span-2 md:col-span-1`])

const Title = styled.h1(() => [
  tw`flex w-full text-2xl md:text-[40px] justify-start font-bold text-spaceGray mb-0 md:mb-6  mt-10 md:mt-20`
])

const Category = styled.p(() => [tw`text-lg md:text-[20px] text-spaceGray m-0 whitespace-pre-wrap`])

const ShortDesc = styled.p(() => [
  tw`text-xl md:text-2xl text-spaceGray font-semibold flex items-end h-full m-0 whitespace-pre-wrap`
])

const HeroImg = styled.img(() => [tw`w-screen flex justify-center mb-6 md:mb-28 h-[238px] md:h-[606px] object-cover`])

const DetailContainer = styled.div(() => [
  tw`grid grid-cols-2 max-w-full gap-y-4 md:gap-y-6 md:gap-20 bg-transparent mb-6 md:mb-20`
])

const DetailRight = styled.p(() => [tw`col-span-2 md:col-span-1 text-[16px] text-spaceGray m-0 whitespace-pre-wrap`])

const DetailLeftContainer = styled.div(() => [
  tw`col-span-2 md:col-span-1 grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-4 md:gap-y-8 overflow-hidden`
])

const DetailLeftBox = styled.div<{ span2?: boolean }>(({ span2 }) => [
  tw`col-span-2`,
  ...(span2 ? [tw`md:col-span-2`] : [tw`md:col-span-1`])
])

const DetailLeftTitle = styled.p(() => [tw`text-[16px] text-orange m-0 `])

const DetailLeft = styled.p(() => [tw`text-[16px] font-bold text-spaceGray m-0 whitespace-pre-wrap`])

interface OurWorkDetailSectionProps {
  className?: string
  data: WorkTemplateProps
}

const OurWorkDetailSection: React.FC<OurWorkDetailSectionProps> = ({ className, data }) => (
  <StyledContainer className={className}>
    <Container>
      <Title>{data.strapiOurWorks.Title}</Title>
      <HeadingContainer>
        <ValueBox>
          <Category>{data.strapiOurWorks.Category}</Category>
        </ValueBox>
        <ValueBox>
          <ShortDesc>{data.strapiOurWorks.ShortDesc}</ShortDesc>
        </ValueBox>
      </HeadingContainer>
    </Container>
    <HeroImg src={data.strapiOurWorks.HeroImage.localFile.publicURL} />
    <Container>
      <DetailContainer>
        <DetailRight>{data.strapiOurWorks.LongDesc}</DetailRight>
        <DetailLeftContainer>
          <DetailLeftBox>
            <DetailLeftTitle>Client</DetailLeftTitle>
            <DetailLeft>{data.strapiOurWorks.Client}</DetailLeft>
          </DetailLeftBox>
          <DetailLeftBox>
            <DetailLeftTitle>Type of work</DetailLeftTitle>
            <DetailLeft>{data.strapiOurWorks.TypeOfWork}</DetailLeft>
          </DetailLeftBox>
          <DetailLeftBox>
            <DetailLeftTitle>View website</DetailLeftTitle>
            <a className="block w-fit" href={data.strapiOurWorks.Website} target="_blank" rel="noreferrer noopener">
              <DetailLeft>Link</DetailLeft>
            </a>
          </DetailLeftBox>
          <DetailLeftBox>
            <DetailLeftTitle>Year</DetailLeftTitle>
            <DetailLeft>{data.strapiOurWorks.Year}</DetailLeft>
          </DetailLeftBox>
        </DetailLeftContainer>
      </DetailContainer>
    </Container>
  </StyledContainer>
)

export default OurWorkDetailSection
